export interface MailProps {
  to: string;
  subject: string;
  body: string;
}

export interface TemplateMailProps {
  to: string;
  templateId: string;
  dynamicTemplateData: {
    subject: string;
    name: string;
    message: string;
    url?: string;
    urlLabel?: string;
    sideUrl?: string;
    policyUrl?: string;
    rulesUrl?: string;
    fullUrl?: string;
    sideFooter?: string;
  };
}

export const EmailSubjectContent = {
  THANK_YOU: {
    subject: 'Thanks for entering',
    message: `You're like a burst of confetti in our sweepstakes party – and we love it!
      Thanks for jumping into the fun with us. We promise, there are surprises and excitement waiting just around the corner.      
      Stay tuned`,
  },
  WINNER: {
    subject: `Congratulations! You're Our Sweepstakes Winner!`,
    message: `Exciting news! You've won our sweepstakes!
      We're thrilled to announce that you're the lucky winner.
      Congratulations,
      Best regards,`,
  },
  PRIZE_CLAIMED: {
    subject: 'Your Prize was claimed',
    message1: `We're glad you claimed your prize.
    We would like to invite you to share your positive experience on social “LINK HERE”    
    Cheers`,
  },
  REMINDER: {
    subject: `Don't Forget to Claim Your Prize!`,
    message: `We hope this message finds you well. We wanted to remind you that you have an amazing prize waiting just for you, and we don't want you to miss out on the fun!
    We are excited for you to enjoy your prize
    Claiming your prize is easy.  Just “CLICK HERE”`,
  },
  CONCILIATORY: {
    subject: 'Conciliatory',
    message:
      "We hope you'll continue to pick up Jackpot at your favorite dispensary as we continue to expand our offerings. Our mission at Jackpot.buzz is to pair up experiences and add value to our customers wherever we can. Know that part of being a jackpot.buzz customer means you are already a winner. Enjoy these perks from our partners as proof that you're indeed lucky. You can re-enter the sweepstakes with every purchase of course.",
  },
};
