import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useFormValidations, useTicket } from '../../hooks';
import {
  getSubmission,
  saveSubmission,
  sendSubmissionEmail,
} from './sweepstakes.service';
import { UseSweepstakes } from './sweepstakes.model';
import { SweepstakesSchema, SweepstakesSchemaType } from './sweepstakes.schema';
import { useNavigate } from 'react-router-dom';
import { EmailSubjectContent, TemplateMailProps } from '../../core';
import { useEventDate } from '../../context/eventDateContext';

const TEMPLATE_ID = process.env.REACT_APP_SEND_GRID_TEMPLATE_ID;

export const useSweepstakes = (): UseSweepstakes => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { date } = useEventDate(); 
  const { getTicketId, getIsAutoGenerated, setIsAutoGenerated, setTicketId } =
    useTicket();
  const navigate = useNavigate();

  const {
    errors: formErrors,
    control,
    submit,
    setValue,
  } = useFormValidations<SweepstakesSchemaType>({
    schema: SweepstakesSchema,
    onSubmit: async (data: SweepstakesSchemaType) => {
      handleSubmit(data);
    },
  });

  const showToast = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: 'top-center',
      hideProgressBar: false,
      autoClose: 3000,
    });
  };

  const handleSubmit = async (data: SweepstakesSchemaType) => {
    setIsSubmitting(true);
    const isSubmitted = await handleIsSubmitted();

    if (isSubmitted) {
      showToast(
        `Cannot use the ticket ${getTicketId()}, it was already submitted`
      );
      setIsSubmitting(false);
      return;
    }

    const res = await saveSubmission(data);
    if (res.status === 201) {
      setIsSubmitting(false);
      const content: TemplateMailProps = {
        to: data.email,
        templateId: TEMPLATE_ID!,
        dynamicTemplateData: {
          subject: EmailSubjectContent.THANK_YOU.subject,
          name: data.firstName.toUpperCase(),
          message: EmailSubjectContent.THANK_YOU.message,
          sideUrl: 'Jackpot.buzz',
          policyUrl : 'https://jackpot.buzz/private-policy',
          rulesUrl: 'https://jackpot.buzz/official-rules',
          fullUrl: 'https://jackpot.buzz/',
          sideFooter: 'JACKPOT.BUZZ'
        },
      };
      sendSubmissionEmail(content);
      setIsAutoGenerated(false);
      setTicketId('');
      navigate('/entry-received');
    }
  };

  const handleIsSubmitted = async (): Promise<boolean> => {
    const res = await getSubmission(getTicketId());
    return !!res;
  };

  useEffect(() => {
    setValue('isAutoGenerated', getIsAutoGenerated());
    setValue('ticketId', getTicketId());
  }, []);

  useEffect(() => {
    if (formErrors['ticketId']) {
      toast.error(formErrors['ticketId']?.message as string);
    }
  }, [formErrors]);

  return {
    control,
    formErrors,
    isSubmitting,
    ticketId: getTicketId(),
    submit,
    date,
  };
};
